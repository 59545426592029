import React from "react"
import Img from "gatsby-image"
import StyledLink from "./styledlink"

export default function PostItem(props) {
    return (
        
            <div className="card">
                <div className="card-image image">
                    <Img 
                        style={{maxHeight: "300px"}} 
                        imgStyle={{ maxHeight: "300px", opacity: 0.8}} 
                        fluid={props.fluidImage} 
                    />
                </div>
                <div className="card-content">
                    <div className="content">
                    
                        <h2 className="title">
                            <StyledLink to={props.to}>{props.title}</StyledLink>
                        </h2>
                        <p><em><time style={{fontSize: "0.9rem", color:"hsl(0, 0%, 21%)"}} datetime={props.date}>{props.date}</time></em></p>
                        <p className="mt-1">{props.excerpt}</p>
                        {
                            props.tags && props.tags.map((tag) => {
                                return (
                                    <span className="tag is-success mr-1">{tag}</span>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

    )
}
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import PostItem from "../components/post"


export default function BlogPage(props) {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
                edges {
                    node {
                        frontmatter {
                            title
                            date(formatString: "MMMM Do, YYYY")
                            thumbnail {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            tags
                        }
                        fields {
                            slug
                        }
                        excerpt
                    }
                }
            }
        }
    `)
    return (
        <>
            <Helmet title={`Blog | ${data.site.siteMetadata.title}`} />


            <h1 className="title">Blog</h1>

            <div className="content">
                <div className="columns is-multiline">
                    {data.allMarkdownRemark.edges.map((edge, index) => {
                        const spacing = index === 0 ? 'is-full' : 'is-half'
                        return (
                            <div className={`column ${spacing}`}>
                                <article className="tile is-child">
                                    <PostItem
                                        to={`/blog/${edge.node.fields.slug}`}
                                        title={edge.node.frontmatter.title}
                                        fluidImage={edge.node.frontmatter.thumbnail.childImageSharp.fluid}
                                        date={edge.node.frontmatter.date}
                                        excerpt={edge.node.excerpt}
                                        tags={edge.node.frontmatter.tags}
                                    />
                                </article>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}